import React, {useEffect, useState} from 'react';


import './allCourses.css';
import {Carousel} from "primereact/carousel";

import axios from "axios";
import {ip} from "../../../../ip";
import PaymentModal from "../paymentModal/PaymentModal";
import {storage} from "../../../../services";
import Module from "./module/Module";

const AllCourses = () => {
    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const [isOpenModal , setIsOpenModal] = useState(false);
    const openPayModal = () =>{
        setIsOpenModal(true);
    }

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const [products, setProducts] = useState([]);
    const [authorCourses, setAuthorCourses] = useState([]);
    useEffect(() => {
        axios.get(`${ip}/api/v1/course/getAllCourse` ,
            {headers:{'Authorization': `Bearer ${token}`}}
            )
            .then((res) => {
                setProducts(res?.data?.item);
            }).catch((err)=>{})
        axios.get(`${ip}/api/v1/authorship/getAllAuthorCourse`)
            .then((res) => {
                setAuthorCourses(res?.data?.item);
            }).catch((err)=>{})
    }, [])

    const productTemplate = (product) => {
            return(
                <div className="all_courses_carousel" onClick={() => getModuleCourse(product)}>
                    <div className="all_courses_carousel_head">
                        <img loading="lazy" src={`${ip}/api/photo/show/${product?.coverPhotoId}`}/>
                    </div>
                    <div className="all_courses_carousel_line"></div>
                    <div className="all_courses_carousel_body">
                        <p>{product?.description}</p>
                    </div>
                </div>
            )
    };
    
    const [openModulePage , setOpenModulePage] = useState(false);
    const [moduleData , setModuleData] = useState([]);
    const getModuleCourse = (value) => {
        axios.get(`${ip}/api/v1/course/getCourse?courseId=${value?.id}&isAuthorshipCourse=${value?.isAuthorshipCourse}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setModuleData(res?.data?.item);
                setOpenModulePage(true);
            }).catch((err)=>{});
    }

    return (
        <div>
            {openModulePage ?
            <Module
                setOpenModulePage={setOpenModulePage}
                moduleData={moduleData}
                setModuleData={setModuleData}
            />
            :
                <div className="all_courses">
                    <div className="all_courses_header">
                        <h2>YAGONA TO'LOV</h2>
                    </div>
                    <div className="all_courses_body">
                        <div className="all_courses_body_carousel">
                            <Carousel value={products} numVisible={5} numScroll={3}
                                      responsiveOptions={responsiveOptions} itemTemplate={productTemplate}/>
                        </div>
                        <div className="all_courses_body_price">
                            <p>OBUNA BO’LISH NARXI - 347 000 SO’M</p>
                            <button onClick={openPayModal}>XARID QILISH</button>
                        </div>
                        <div className="all_courses_body_cards">
                            {
                                authorCourses?.map((item , index)=>{
                                    return(
                                        <div className="all_courses_carousel_card" key={index}>
                                            <div className="all_courses_carousel_card_head">
                                                <img loading="lazy" src={`${ip}/api/photo/show/${item?.coverPhotoId}`}/>
                                            </div>
                                            <div className="all_courses_carousel_card_line"></div>
                                            <div className="all_courses_carousel_card_body">
                                                <p>{item?.description}</p>
                                            </div>
                                            <div className="all_courses_carousel_card_footer">
                                                <button className="all_courses_carousel_card_footer_left"
                                                        onClick={() => getModuleCourse(item)}>MODULLAR</button>
                                                <button className="all_courses_carousel_card_footer_right"
                                                        onClick={() => getModuleCourse(item)}>{item?.price} so'm</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <PaymentModal
                        isOpenModal={isOpenModal}
                        setIsOpenModal={setIsOpenModal}
                    />
                </div>
            }
        </div>
    );
};

export default AllCourses;