import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import iconImg from '../../../../../../images/user/ic.png';
import {IoMdClose} from "react-icons/io";
import {IoCloseCircleSharp} from "react-icons/io5";
import {Form, Input, message} from "antd";

import './paymentVerify.css';
import axios from "axios";
import {ip} from "../../../../../../ip";
import {storage} from "../../../../../../services";
import Loader from "../../../../../loading/Loader";

const PaymentVerify = (props) => {
    const [loading, setLoading] = useState(false);

    const token = storage.local.get("token");
    const {
        openVerify,
        setOpenVerify,
        verify,
        moduleData,
        cancelModal
    } = props;

    const [cardNumber, setCardNumber] = useState(null);
    const [cardData, setCardData] = useState(null);
    const [card, setCard] = useState({
        cardno: "",
        cardtype: "far fa-credit-card",
        expirydt: ""
    });

    const cancel = () => {
        setOpenVerify(false);
        setCard({
            cardno: "",
            cardtype: "far fa-credit-card",
            expirydt: ""
        })
    }

    const onFinish = (values) => {
        setLoading(true)
        axios.post(`${ip}/api/v1/payment/verify-to-purchase`,
            {
                code : values.code,
                transactionId : verify?.transactionId,
                courseId : moduleData?.id
            },
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                cancel();
                message.success(res?.data?.message);
                setLoading(false);
                cancelModal();
            }).catch((err)=>{
            message.error(err?.response?.data?.message);
            setLoading(false);
            })
    }

    const onFinishFailed = (error) => {
        // console.log(error)
    }
    return (
        <Modal
            isOpen={openVerify}
            onRequestClose={cancel}
            contentLabel="My dialog"
            className="myModal"
            overlayClassName="myOverlay"
            closeTimeoutMS={300}
        >
            {
                loading && <Loader/>
            }
            <Form
                name="basic"
                layout="vertical"
                // initialValues={staffTableIntialValues}
                requiredMark = 'optional'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="payment_modal_verify">
                    <div className="payment_modal_verify_cancel" onClick={cancel}>
                        {/*<IoMdClose />*/}
                        <IoCloseCircleSharp size={"20px"}/>
                    </div>
                    <div className="payment_modal_verify_inner">
                        <div className="payment_modal_verify_inner_title">
                            <h2>TASTIQLANG</h2>
                        </div>

                        <div className="payment_modal_verify_inner_validity_period">
                            <p>{verify?.message}</p>
                            <Form.Item
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tastiqlash kodini kiriting !',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>

                        <div className="payment_modal_verify_inner_button">
                            <button type="submit">YUBORISH</button>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default PaymentVerify;

