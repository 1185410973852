import React from 'react';
import {Pagination} from "antd";

import styled from "styled-components";
export const PaginationStyles = styled(Pagination)`
  .ant-pagination-item, .ant-pagination-item-link, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  
`;

const CooperationPagination = (props) => {

    const {
        cooperationTotal,
        cooperationPaginationCurrent,
        cooperationPaginationLimit,
        cooperationPaginationOnchange
    }=props

    return<PaginationStyles
        dropdownRender = {false}
        defaultPageSize={cooperationPaginationLimit}
        current={cooperationPaginationCurrent}
        onChange={cooperationPaginationOnchange}
        showSizeChanger={false}
        total={cooperationTotal}
        pageSize={cooperationPaginationLimit}
        pageSizeOptions={[10, 20, 30, 40]}
        locale={{ items_per_page: 'sahifa'}}
    />;
};

export default CooperationPagination;