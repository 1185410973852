import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import iconImg from '../../../../../../images/user/ic.png';
import {IoMdClose} from "react-icons/io";
import {IoCloseCircleSharp} from "react-icons/io5";
import {Form, Input, message} from "antd";

import './paymentModal.css';
import axios from "axios";
import {ip} from '../../../../../../ip';
import {storage} from "../../../../../../services";
import Loader from "../../../../../loading/Loader";
import PaymentVerify from "./PaymentVerify";

const PaymentModal = (props) => {
    const [openVerify, setOpenVerify] = useState(false)
    const [loading, setLoading] = useState(false);

    const token = storage.local.get("token");
    const {
        openPayModal,
        setOpenPayModal,
        moduleData
    } = props;

    const [cardNumber, setCardNumber] = useState(null);
    const [cardData, setCardData] = useState(null);
    const [card, setCard] = useState({
        cardno: "",
        cardtype: "far fa-credit-card",
        expirydt: ""
    });

    const onChange = (e) => {
        var cartype_new = cardnumber(e.target.value);
        setCard({
            ...card,
            cardno: e.target.value,
            cardtype: cartype_new
        });
    };
    const cardnumber = (inputtxt) => {
        var matches = inputtxt.match(/(\d+)/);
        var cardno = "";
        // console.log(matches);
        if (matches) {
            cardno = inputtxt.split(" ").join("");
        }
        // console.log(cardno);
        var cardtype1 = card.cardtype;
        //var visa = /^(?:4[0-9]{16}(?:[0-9]{3})?)$/;
        var visa = /^(?:4[0-9]{2}?)$/;
        var mastercardRegEx = /^(?:5[1-5][0-9]{3})$/;
        var amexpRegEx = /^(?:3[47][0-9]{3})$/;
        var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{5})$/;
        // console.log(visa.test(cardno));
        if (visa.test(cardno) === true) {
            //eg:4651970022334445
            cardtype1 = "far fa fa-3x fa-cc-visa  carddetail-cardtype";
        } else if (mastercardRegEx.test(cardno) === true) {
            cardtype1 = "far fa fa-3x fa-cc-mastercard carddetail-cardtype";
        } else if (amexpRegEx.test(cardno) === true) {
            cardtype1 = "far fa fa-3x fa-cc-amex carddetail-cardtype";
        } else if (discovRegEx.test(cardno) === true) {
            cardtype1 = "far fa fa-3x fa-cc-discover carddetail-cardtype";
        }
        return cardtype1;
    };

    const cc_format = (value) => {
        const v = value.replace(/[^0-9]/gi, "").substr(0, 16);
        const parts = [];
        for (let i = 0; i < v.length; i += 4) {
            parts.push(v.substr(i, 4));
        }
        return parts.length > 1 ? parts.join(" ") : value;
    };

    const expriy_format = (value) => {
        const expdate = value;
        const expDateFormatter =
            expdate.replace(/\//g, "").substring(0, 2) +
            (expdate.length > 2 ? "/" : "") +
            expdate.replace(/\//g, "").substring(2, 4);
        return expDateFormatter;
    };
    const onChangeExp = (e) => {
        setCard({
            ...card,
            expirydt: e.target.value
        });

    };

    useEffect(() => {
        setCardNumber(cc_format(card.cardno).split(" ").join(""));
        setCardData(expriy_format(card.expirydt).split("/").join(""));
    }, [card]);

    const cancelModal = () => {
        setOpenPayModal(false);
        setCard({
            cardno: "",
            cardtype: "far fa-credit-card",
            expirydt: ""
        })
    }
    const [verify, setVerify] = useState([]);

    const onFinish = (values) => {
        setLoading(true);
        const formData = {
            number: cardNumber,
            expire: cardData,
            amount: moduleData?.price,
            durationPrem: 0,
            isSubscribe: false
        };
        axios.post(`${ip}/api/v1/payment/get-verify-code`,
            formData,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setOpenVerify(true);
                setVerify(res?.data?.item);
                setLoading(false);
                message.success("Muaffaqqiyatli");
            }).catch((err)=>{
            message.error(err?.response?.data?.message);
            setLoading(false);
        })
    }

    const onFinishFailed = (error) => {}

    return (
        <Modal
            isOpen={openPayModal}
            // onRequestClose={cancelModal}
            contentLabel="My dialog"
            className="myModal"
            overlayClassName="myOverlay"
            closeTimeoutMS={300}
        >
            {
                loading && <Loader/>
            }
            <Form
                name="basic"
                layout="vertical"
                // initialValues={staffTableIntialValues}
                requiredMark='optional'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="payment_modal">
                    <div className="payment_modal_cancel" onClick={cancelModal}>
                        {/*<IoMdClose />*/}
                        <IoCloseCircleSharp size={"20px"}/>
                    </div>
                    <div className="payment_modal_inner">
                        <div className="payment_modal_inner_title">
                            <h2>KARTA MA’LUMOTLARINGIZNI KIRITING</h2>
                        </div>
                        <div className="payment_modal_inner_card_number">
                            {/*<Form.Item*/}
                            {/*    name="card"*/}
                            {/*    rules={[*/}
                            {/*        {*/}
                            {/*            required: true,*/}
                            {/*            message: 'Karta raqamini kiriting !',*/}
                            {/*        },*/}
                            {/*    ]}*/}
                            {/*>*/}
                            <input
                                type="text"
                                data-mask="0000 0000 0000 0000"
                                placeholder="XXXX XXXX XXXX XXXX"
                                value={cc_format(card.cardno)}
                                onChange={onChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {/*</Form.Item>*/}
                        </div>
                        <div className="payment_modal_inner_validity_period">
                            <p>Amal qilish muddati</p>
                            <div className="payment_modal_inner_validity_period_inner">
                                <input
                                    placeholder="XX/XX"
                                    onChange={onChangeExp}
                                    value={expriy_format(card.expirydt)}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {/*</Form.Item>*/}
                            </div>
                        </div>

                        {/*<div className="payment_modal_inner_payment">*/}
                        {/*    <p>Xarid qilinayotgan kurs:</p>*/}
                        {/*    <p>Umumiy obuna</p>*/}
                        {/*</div>*/}
                        <div className="payment_modal_inner_text">
                            <img src={iconImg}/>
                            <p>
                                Xarid “Payme” to’lov tizimi orqali amalga oshiriladi. Sizdan hech qanday ortiqcha summa
                                yechilmaydi va karta ma’lumotlaringiz maxfiy qoladi.
                            </p>
                        </div>
                        <div className="payment_modal_inner_button">
                            <button type="submit">XARID QILISH</button>
                        </div>
                    </div>
                </div>
            </Form>
            <PaymentVerify
                setOpenVerify={setOpenVerify}
                openVerify={openVerify}
                verify={verify}
                moduleData={moduleData}
                cancelModal={cancelModal}
            />
        </Modal>
    );
};

export default PaymentModal;