import React, {useEffect, useState} from 'react';

import './cooperation.css'
import CooperationTable from "./CooperationTable";
import CooperationPagination from "./CooperationPagination";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";
import {Alert, message, Image , Form , Input} from "antd";
import {BiLoader} from "react-icons/bi";
import Modal from "react-modal";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import Loader from "../../../loading/Loader";
import img from '../../../../images/landingImage/Sales funnels (2) 4.png';
const Cooperation = () => {

    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const [loading , setLoading] = useState(false)

    const [clickers, setClickers] = useState([]);
    const [clickersCount, setClickersCount] = useState('');
    const [balance, setBalance] = useState([]);
    const [referralId, setReferralId] = useState(null);
    const [myTicket, setMyTicket] = useState(null);

    const [cooperationTotal, setcooperationTotal] = useState(clickers?.length);
    const [cooperationPaginationLimit, setcooperationPaginationLimit] = useState(10);
    const [cooperationPaginationCurrent, setcooperationPaginationCurrent] = useState(1);
    const cooperationPaginationOnchange = (e = 1, option) => {
        setcooperationPaginationCurrent(e)
        setcooperationPaginationLimit(option)
    }

    const getMyTicket = () =>{
        axios.get(`${ip}/api/referral/getMyTicket`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setMyTicket(res?.data?.item);
            }).catch((error) => {message.error("Xatolik !")})
    }

    useEffect(() => {
        getMyTicket();
        axios.get(`${ip}/api/referral/referral/getClickers`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                const newData = res?.data?.item?.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        fullName: item.fullName,
                        bonus: item.bonus,
                        createdAt: item.createdAt,
                        ownerId: item.ownerId,
                        referralStatus: item.referralStatus,
                        userId: item.userId,
                        username: item.username,
                        id: item.id,
                    }
                ))
                setClickers(newData);
            }).catch((error) => {
        });
        axios.get(`${ip}/api/referral/referral/getClickerCount`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setClickersCount(res?.data?.item)}).catch((error) => {
        })
        axios.get(`${ip}/api/referral/referral/getBalance`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setBalance(res?.data);}).catch((error) => {
        })
        axios.get(`${ip}/api/referral/referral/getReferralId`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setReferralId(res?.data?.item);
            }).catch((error) => {})
    }, []);

    let payed = 0;
    clickers.forEach(item => {
        if (item.referralStatus === "NOT_PAID" || item.referralStatus === "PAYED") {
            payed++;
        }
    });

    const link = `https://prodigitalcourse.uz/referral-register?referral_id=${referralId}`;
    const handleLinkClick = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(link)
            .then(() => {message.success("Havola vaqtinchalik xotiraga nusxalandi!")})
            .catch((err) => {message.error(`Nusxalab bo‘lmadi: ${err}`)});
    };

    // applicationModal
    const [applicationModal, setApplicationModal] = useState(false);
    const cencelApplication = () => {
        setApplicationModal(false);
        setLoading(true);
        axios.put(`${ip}/api/referral/closeTicket?ticketId=${myTicket?.id}`,
            {},
            {headers: {'Authorization': `Bearer ${token}`}})
            .then((res) => {
                getMyTicket();
                setLoading(false);
            }).catch((error) => {message.error("Xatolik !")
        })
    }
    // applicationModal


    // withDrawModal

    const [cardNumber, setCardNumber] = useState(null);
    const [card, setCard] = useState({
        cardno: "",
        cardtype: "far fa-credit-card",
        expirydt: ""
    });

    const onChange = (e) => {
        var cartype_new = cardnumber(e.target.value);
        setCard({
            ...card,
            cardno: e.target.value,
            cardtype: cartype_new
        });
    };
    const cardnumber = (inputtxt) => {
        var matches = inputtxt.match(/(\d+)/);
        var cardno = "";
        // console.log(matches);
        if (matches) {
            cardno = inputtxt.split(" ").join("");
        }
        // console.log(cardno);
        var cardtype1 = card.cardtype;
        //var visa = /^(?:4[0-9]{16}(?:[0-9]{3})?)$/;
        var visa = /^(?:4[0-9]{2}?)$/;
        var mastercardRegEx = /^(?:5[1-5][0-9]{3})$/;
        var amexpRegEx = /^(?:3[47][0-9]{3})$/;
        var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{5})$/;
        // console.log(visa.test(cardno));
        if (visa.test(cardno) === true) {
            //eg:4651970022334445
            cardtype1 = "far fa fa-3x fa-cc-visa  carddetail-cardtype";
        } else if (mastercardRegEx.test(cardno) === true) {
            cardtype1 = "far fa fa-3x fa-cc-mastercard carddetail-cardtype";
        } else if (amexpRegEx.test(cardno) === true) {
            cardtype1 = "far fa fa-3x fa-cc-amex carddetail-cardtype";
        } else if (discovRegEx.test(cardno) === true) {
            cardtype1 = "far fa fa-3x fa-cc-discover carddetail-cardtype";
        }
        return cardtype1;
    };

    const cc_format = (value) => {
        const v = value.replace(/[^0-9]/gi, "").substr(0, 16);
        const parts = [];
        for (let i = 0; i < v.length; i += 4) {
            parts.push(v.substr(i, 4));
        }
        return parts.length > 1 ? parts.join(" ") : value;
    };

    useEffect(() => {
        setCardNumber(cc_format(card.cardno).split(" ").join(""));
    }, [card]);

    const [withDrawModal, setWithDrawModal] = useState(false);
    const openWithDrawModal = () =>{
        if (parseInt(balance?.currentBalance) >= 400000){
            setWithDrawModal(true)
        }else {
            message.error("Bonus miqdoringiz 400 000 so’m bo’lgandan keyingina uni kartangizga yechib olsangiz bo’ladi !")}
    }
    const cencelWithDrawModal = () => {
        setWithDrawModal(false);
        setCard({
            cardno: "",
            cardtype: "far fa-credit-card",
            expirydt: ""
        })
    }
    const onFinish = (values) => {
        // setLoading(true);
            axios.post(`${ip}/api/referral/openTicket?ticketType=PARTNER_TICKET`,
                {cardNumber : cardNumber , cardHolderName : values?.cardHolderName},
                {headers: {'Authorization': `Bearer ${token}`}})
                .then((res) => {
                    getMyTicket();
                    cencelWithDrawModal();
                    // setLoading(false);
                    message.success("Muaffaqqiyatli")
                }).catch((err) => {
                message.error( err?.response?.data?.message);
                // setLoading(false);
            })
        }
    const onFinishFailed = (error) => {}

    // withDrawModal

    return (
        <div className="cooperation">
            {
                loading && <Loader/>
            }
            <div className="cooperation_header">
                <div className="cooperation_header_left">
                    <div className="cooperation_header_left_top">MIJOZLAR</div>
                    <div className="cooperation_header_left_bottom">
                        <p>
                            Referal linkingiz orqali platformaga obuna bo’lgan mijozlardan 100 000 so’m miqdorda bonus
                            oling !
                        </p>
                        <p>
                            Siz tomondan kelgan mijoz platformaga obunani xarid qilganidan so’ng bonusingiz ichki
                            hisobingizga tushadi. Bonus miqdoringiz 400 000 so’m bo’lgandan keyingina uni kartangizga
                            yechib olsangiz bo’ladi !
                        </p>
                        <p>
                            Referal linkingiz : <a href={link} onClick={handleLinkClick}>{link}</a>
                        </p>
                    </div>
                </div>
                <div className="cooperation_header_left">
                    <div className="cooperation_header_left_top">STATISTIKA</div>
                    <div className="cooperation_header_left_bottom">
                        <div className="cooperation_header_left_bottom_inner">
                            <div className="cooperation_header_left_bottom_inner_inner">Kliklar</div>
                            <div className="cooperation_header_left_bottom_inner_inner">{clickersCount}</div>
                        </div>
                        <div className="cooperation_header_left_bottom_inner">
                            <div className="cooperation_header_left_bottom_inner_inner">Ro’yxatdan o’tildi</div>
                            <div className="cooperation_header_left_bottom_inner_inner">{clickers?.length}</div>
                        </div>
                        <div className="cooperation_header_left_bottom_inner">
                            <div className="cooperation_header_left_bottom_inner_inner">Xarid qilindi</div>
                            <div className="cooperation_header_left_bottom_inner_inner">{payed}</div>
                        </div>
                        {
                            myTicket ?
                                <div className="cooperation_header_left_bottom_inner">
                                    <div className={myTicket?.ticketProgress === "IN_PROGRESS" ?
                                        "cooperation_header_left_bottom_inner_inner ticketProgress" :
                                        "cooperation_header_left_bottom_inner_inner"}>
                                        {myTicket?.ticketProgress === "IN_PROGRESS" ? "Ariza jarayonda..." : "Admin javob berdi"}
                                    </div>
                                    <div className="cooperation_header_left_bottom_inner_inner">
                                        {myTicket?.ticketProgress === "IN_PROGRESS" ? <BiLoader/> :
                                            <button onClick={() => setApplicationModal(true)}>Arzani ko'rish</button>}
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
            <div className="cooperation__body">
                <div className="cooperation__body_table">
                    <div className="cooperation__body_table_table">
                        <CooperationTable data={clickers}/>
                    </div>
                    {/*<div className="cooperation__body__table_pagination">*/}
                    {/*    <CooperationPagination*/}
                    {/*        cooperationPaginationLimit={cooperationPaginationLimit}*/}
                    {/*        cooperationPaginationCurrent={cooperationPaginationCurrent}*/}
                    {/*        cooperationPaginationOnchange={cooperationPaginationOnchange}*/}
                    {/*        cooperationTotal={cooperationTotal}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className="cooperation__body_balance">
                    <div className="cooperation__body_balance_inner">
                        <h2>BALANS</h2>
                        <div className="cooperation__body_balance_inner_button">{balance?.currentBalance} so’m</div>
                    </div>
                    <div className="cooperation__body_balance_inner">
                        <div className="cooperation__body_balance_inner_button_2"
                             onClick={openWithDrawModal}>HISOBNI YECHISH</div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={applicationModal}
                // onRequestClose={cencelApplication}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="application_modal">
                    <div className="application_modal_header">
                        <h2>Ariza holati</h2>
                    </div>
                    <div className="application_modal_body">
                        <div className="application_modal_body_inner">
                            <div className="application_modal_body_inner_inner"><p>Karta egasi :</p>
                                <p>{myTicket?.cardHolder}</p></div>
                            <div className="application_modal_body_inner_inner"><p>Karta raqami :</p>
                                <p>{myTicket?.cardNumber}</p></div>
                            <div className="application_modal_body_inner_inner"><p>Balans :</p>
                                <p>{myTicket?.withdrawalBalance} so'm</p></div>
                            <div className="application_modal_body_inner_inner">
                                <p>Admin javobi :</p>
                                <p>{myTicket?.hasAdminResMessage ? myTicket?.adminResMessage : ""}</p></div>
                            <div className="application_modal_body_inner_inner">
                                <p>Chek:</p>
                                <p>{myTicket?.hasAdminResPicture ?
                                    <div>
                                        <Image
                                            width={100}
                                            height={80}
                                            style={{objectFit: "contain"}}
                                            src={`${ip}/api/photo/show/${myTicket?.adminResPicture}`}
                                            preview={{
                                                mask: (<MdOutlineRemoveRedEye style={{fontSize: "22px"}}/>),
                                                maskClassName: 'customize-mask',
                                            }}
                                        /><br/>
                                        <span>chekni kattalashtirish mumkin !</span>
                                    </div>
                                    : ""}</p></div>

                            <div className="application_modal_body_button">
                                <button onClick={cencelApplication}>Yopish</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={withDrawModal}
                onRequestClose={cencelWithDrawModal}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="withDraw_modal">
                    <div className="withDraw_modal_header">
                        <h2>Hisobni yechish</h2>
                    </div>
                    <div className="withDraw_modal_body">
                        <Form
                            name="basic"
                            layout="vertical"
                            // initialValues={initialValuesTopic}
                            requiredMark='optional'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                         <div className="withDraw_modal_body_field">
                             <p>Karta raqami</p>
                             <Input
                                 size="large"
                                 data-mask="0000 0000 0000 0000"
                                 placeholder="XXXX XXXX XXXX XXXX"
                                 value={cc_format(card.cardno)}
                                 onChange={onChange}
                                 onKeyPress={(event) => {
                                     if (!/[0-9]/.test(event.key)) {event.preventDefault();}
                                 }}
                             />
                         </div>
                            <div className="withDraw_modal_body_field">
                                <p>Karta egasi</p>
                                <Form.Item
                                    name="cardHolderName"
                                    rules={[{
                                        required: true, message: 'Ismini kiriting',
                                    },]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={"Kiriting"}
                                    />
                                </Form.Item>
                            </div>
                            <div className="library_body_modal_inner_addModal_inner_buttons">
                                <button type='button' onClick={cencelWithDrawModal}>Bekor qilish</button>
                                <button type="submit">Yuborish</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Cooperation;