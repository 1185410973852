import React, {useState} from 'react';

import {Collapse} from "antd";

import './module.css';
import ReactPlayer from "react-player/lazy";
import {ip} from "../../../../../ip";
import videoplayIcon from "../../../../../images/user/userDashboard/videoplayIcon.png";
import PaymentModal from "./paymentModal/PaymentModal";

const Module = (props) => {

    const {
        setOpenModulePage,
        moduleData,
        setModuleData
    } = props;

    const [openPayModal, setOpenPayModal] = useState(false)

    const [videoId, setVideoId] = useState(null);
    const getVideoId = (id) => {
        setVideoId(id);
    }
    const handleContextMenu = (e) => {
        e.preventDefault();
    };
    const cancel = () =>{
        setOpenModulePage(false);
        setModuleData([]);
        setVideoId(null);
    }

    const payment = () => {
        setOpenPayModal(true);
    }


    return (
        <div className="module">
            <div className="module_header">
                <h2>MODUL VA DARSLIKLAR</h2>
            </div>
            <div className="module_body">
                  <div className="module_body_inner_bloc_inner2">
                      <div className="module_body_inner_bloc2_inner2_inner">
                          <h2>{moduleData?.courseName}</h2>
                          {moduleData?.topicDtoList?.map((item, index) => {
                              return (
                                  <Collapse key={index}
                                            items={[
                                                {
                                                    key: item?.id,
                                                    label: item?.name,
                                                    children:
                                                        <ul>
                                                            {
                                                                item?.lessonList?.map((x, y) => {
                                                                    return (
                                                                        <li key={y}
                                                                            onClick={() => getVideoId(x?.videoId)}>{x?.name}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                },
                                            ]}
                                  />
                              )
                          })}
                      </div>
                      <div className="module_body_inner_bloc2_inner2_inner">

                          {/*{*/}
                          {/*    videoId !== null ?*/}
                          {/*        <div className="module_body_inner_bloc2_inner2_inner_video_true" onContextMenu={handleContextMenu}>*/}
                          {/*            <ReactPlayer url={`${ip}/api/v2/video/stream/${videoId}`}*/}
                          {/*                         className={"react-player"}*/}
                          {/*                         controls*/}
                          {/*                         width={"100%"}*/}
                          {/*                         height={"100%"}*/}
                          {/*                         playing={true}*/}
                          {/*                         loop={true}*/}
                          {/*                         config={{ file: { attributes: { controlsList: 'nodownload' } } }}*/}
                          {/*            />*/}
                          {/*        </div>*/}
                          {/*        :*/}
                                  <div className="module_body_inner_bloc2_inner2_inner_inner_video">
                                      <div className="module_body_inner_bloc2_inner2_inner_inner_video_icon">
                                          <img loading="lazy" src={videoplayIcon}/>
                                      </div>
                                  </div>
                          {/*}*/}

                      </div>
                  </div>
                  <div className="module_body_button">
                      <button onClick={cancel}>BEKOR QILISH</button>
                      <button onClick={payment}>XARID QILISH</button>
                  </div>
            </div>

      <PaymentModal
          openPayModal={openPayModal}
          setOpenPayModal={setOpenPayModal}
          moduleData={moduleData}
      />

        </div>
    );
};

export default Module;