import React, {useEffect, useState} from 'react';


import './links.css';
import {Collapse} from "antd";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";

const Links = () => {
    const token = storage.local.get("token");

    const [linksData , setLinksData] = useState([]);

    useEffect(()=>{
        axios.get(`${ip}/api/v1/link/allTopic`,
            {headers:{'Authorization': `Bearer ${token}`}}
        )
            .then((res)=>{
                // console.log(res)
                setLinksData(res?.data?.item);
            }).catch((err)=>{})
    },[])

    return (
        <div className="links">
            <div className="links_header">
                <h2>FOYDALI LINKLAR</h2>
            </div>
            <div className="links_body">
               <div className="links_body_inner">
                   {
                       linksData?.map((item , index)=>{
                           if (index%2==0){
                               return(
                                   <Collapse
                                       items={[{
                                           key: index,
                                           label: item?.name,
                                           children:
                                               <ul>
                                                   {item?.links?.map((x , y)=>{
                                                       return (
                                                           <li key={y}><a href={item?.premium === true ? x?.link : "#"}
                                                                          target={item?.premium === true ? "_blank" : ""}
                                                                          style={{textDecoration : "none" , color: "white"}}>{x?.description}
                                                           </a></li>
                                                       )
                                                   })}
                                               </ul>
                                       },
                                       ]}
                                   />
                               )
                           }

                   })
                   }
               </div>
               <div className="links_body_inner">
                   {
                       linksData?.map((item , index)=>{
                           if (index%2==1){
                               return(
                                   <Collapse
                                       items={[{
                                           key: index,
                                           label: item?.name,
                                           children:
                                               <ul>
                                                   {item?.links?.map((x , y)=>{
                                                       return(
                                                           <li key={y}><a href={item?.premium === true ? x?.link : "#"}
                                                                          target={item?.premium === true ? "_blank" : ""}
                                                                          style={{textDecoration : "none" , color: "white"}}>{x?.description}
                                                           </a></li>
                                                       )
                                                   })}
                                               </ul>
                                       },
                                       ]}
                                   />
                               )
                           }

                   })
                   }
               </div>
            </div>
        </div>
    );
};

export default Links;